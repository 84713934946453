import { graphql, useStaticQuery } from 'gatsby';

function useCarpentryService() {
  const data = useStaticQuery(
    graphql`
      query CarpentryServiceQuery {
        wpgraphql {
          serviceBy(serviceId: 156) {
            acf_service_info {
              serviceTitle
              serviceShortDescription
              serviceUrl
              background {
                sourceUrl
              }
              icon {
                sourceUrl
              }
            }
            acf_text_block_title {
              title
              content
            }
            acf_image_text_block {
              blockList {
                title
                content
                image {
                  sourceUrl
                }
              }
            }
            acf_services_block {
              servicesList {
                serviceTitle
                serviceSubtitle
                serviceDescription
                secondSubtitle
                secondDescription
                serviceGallery {
                  sourceUrl
                }
              }
            }
            acf_projects_slider {
              projectList {
                projectName
                projectGallery {
                  sourceUrl
                }
              }
            }
            acf_quote {
              descriptionQuote
              phoneNumber
              titleQuoteSection
              iconQuote {
                sourceUrl
              }
              backgroundImage {
                sourceUrl
              }
            }
          }
        }
      }
    `,
  );
  return data.wpgraphql;
}

export { useCarpentryService };
