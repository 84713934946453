import { Box, Container, Heading, HStack, Text } from '@chakra-ui/layout';
import React from 'react';
import Layout from '../../../components/Layout';
import SEO from '../../../components/seo';
import { ContactUsBannerWithForm, Hero, SubHero, CompareSlider, MainServiceBanner } from '../../../components/shared';
import { useCarpentryService } from '../../../GraphQL/Queries/useCarpentryService';

const Carpentry = ({ location }) => {
  const data = useCarpentryService();

  const {
    acf_service_info,
    acf_text_block_title,
    acf_image_text_block,
    acf_services_block,
    acf_projects_slider,
  } = data.serviceBy;

  return (
    <Layout location={location}>
      <SEO title="Carpentry" />
      <Hero
        image={acf_service_info.background.sourceUrl}
        icon={acf_service_info.icon.sourceUrl}
        heroHeadline={acf_service_info.serviceTitle}
        heroCopy={acf_service_info.serviceShortDescription}
        ctaText="Get a quote"
        fullHeight
      />
      <SubHero heading={acf_text_block_title.title} body={acf_text_block_title.content} />

      <MainServiceBanner
        image={acf_image_text_block.blockList[0].image.sourceUrl}
        title={acf_image_text_block.blockList[0].title}
        noCta
        description={acf_image_text_block.blockList[0].content.replace(/<\/?[^>]+(>|$)/g, '')}
      />

      <Box mt={{ base: '2rem', md: '10rem' }} mb={{ base: '12rem', md: '10rem' }}>
        <Container>
          <HStack spacing={{ md: '1rem' }} flexWrap="wrap">
            <Box minW={{ md: '400px' }} flex="1" px="2rem" mb={{ base: '2rem', md: 'unset' }}>
              <Heading color="primary" fontSize="35px" maxW={{ base: '280px', md: 'unset' }} mb="1rem">
                {acf_services_block.servicesList[0].serviceTitle}
              </Heading>

              <Text color="primary" fontSize="25px" fontWeight="600">
                {acf_services_block.servicesList[0].serviceSubtitle}
              </Text>
              <Text textStyle="p" my="0.5rem">
                {acf_services_block.servicesList[0].serviceDescription}
              </Text>

              <Text color="primary" fontSize="25px" fontWeight="600">
                {acf_services_block.servicesList[0].secondSubtitle}
              </Text>
              <Text textStyle="p" my="0.5rem">
                {acf_services_block.servicesList[0].secondDescription}
              </Text>
            </Box>
            <Box h={{ base: '300px', md: '500px' }} minW={{ base: '100%', md: '650px' }} flex="1" my="1.5rem">
              <CompareSlider
                image1={acf_services_block.servicesList[0].serviceGallery[0].sourceUrl}
                image2={acf_services_block.servicesList[0].serviceGallery[1].sourceUrl}
                height="100%"
              />
            </Box>
          </HStack>

          <Box mt={{ base: '6rem', md: '4rem' }} h={{ base: '300px', md: '570px' }}>
            <Heading color="primary" fontSize="35px" my="2rem" pl={{ base: '2rem', md: 'unset' }}>
              {acf_projects_slider.projectList[0].projectName}
            </Heading>
            <CompareSlider
              image1={acf_projects_slider.projectList[0].projectGallery[0].sourceUrl}
              image2={acf_projects_slider.projectList[0].projectGallery[1].sourceUrl}
              height="100%"
            />
          </Box>
        </Container>
      </Box>

      <Box mb={{ md: '20rem' }}></Box>

      <ContactUsBannerWithForm
        isCommercial
        lightBackground
        title="Get a Free Quote"
        image="/images/light-white-building.jpg"
        description="For any of your commercial carpentry needs, you can count on CH Global Construction to deliver quality service, exceptional workmanship, and customer satisfaction. Fill out the form to request a free quote today!"
      />
    </Layout>
  );
};

export default Carpentry;
